<template>
  <v-container fluid>
    <v-row class="d-flex justify-content-center">
      <v-col cols="12" xl="6" lg="7" md="10" class="p-0">
        <v-card :flat="$vuetify.breakpoint.mobile" class="mt-5">
          <v-card-title class="pl-1">
            <v-btn text left color="primary" :to="{name:'pano'}">
              <v-icon small class="mr-1">mdi-arrow-left</v-icon>
              Panoya Git
            </v-btn>
          </v-card-title>
          <v-card-text class="px-0">
            <v-sheet class="p-4" shaped min-height="200">
              <v-row>
                <v-col cols="auto" class="pt-2">
                  <v-avatar size="32" rounded>
                    <v-icon large>mdi-account-circle</v-icon>
                  </v-avatar>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mobile ? '10' : 'auto grow'">
                  <v-row>
                    <v-col class="pb-0 pt-2 pl-0">
                      <span class="font-size-lg font-weight-bold">  {{ editedItem.Title }}</span>
                    </v-col>
                    <v-col class="py-0 pr-0 d-flex justify-content-end">
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              small
                              icon
                              v-bind="attrs"
                              v-on="on"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item class="px-0" v-if="editedItem.ContentTypeId != 4">
                            <v-btn block text @click="downloadAll(editedItem.ContentFiles)">
                              <v-icon left class="mr-1" small color="primary">mdi-download</v-icon>
                              İndir
                            </v-btn>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-btn text @click="sendReport()">
                              <v-icon left class="mr-1" small color="red">mdi-exclamation</v-icon>
                              Şikayet Et
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pl-0">
                      <span class="text-muted"> {{ editedItem.CreatedBy }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="editedItem.Description && editedItem.ContentTypeId != 4">
                <v-col cols="12" class="pt-0">
                  <v-icon small class="mr-1">mdi-text</v-icon>
                  {{ editedItem.Description }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="p-0">
                  <v-img v-if="editedItem.ContentTypeId == 1 && editedItem.ContentFiles.length > 0"
                         @click.stop="openUrl(editedItem.ContentFiles[0].FilePublicUrl)"
                         :src="editedItem.ContentFiles[0].FilePublicUrl"
                         :max-height="$vuetify.breakpoint.mobile ? 320: 550"></v-img>
                  <video v-else-if="editedItem.ContentTypeId == 2 && editedItem.ContentFiles.length > 0" controls=""
                         name="media">
                    <source :src="editedItem.ContentFiles[0].FilePublicUrl "
                            :type="editedItem.ContentFiles[0].FileType">
                  </video>
                  <v-carousel v-else-if="editedItem.ContentTypeId == 3 || editedItem.ContentTypeId == 5"
                              progress-color="green" hide-delimiter-background
                              :height="$vuetify.breakpoint.mobile ? 320: 550" :continuous="false"
                              delimiter-icon="mdi-circle-slice-8" hide-delimiters>
                    <v-carousel-item v-for="item in editedItem.ContentFiles" :key="item.Id">
                      <v-sheet height="100%" rounded tile color="" @click.stop="openUrl(item.FilePublicUrl)">
                        <v-row
                            class="fill-height"
                            align="top"
                            justify="center"
                        >
                          <v-img contain :id="editedItemId + '-' + item.Id" v-if="item.FileType.includes('image')"
                                 :src="item.FilePublicUrl" :max-height="$vuetify.breakpoint.mobile ? 330: 558"></v-img>
                          <video v-else-if="item.FileType.includes('video')" controls="" name="media" width="95%">
                            <source :src="item.FilePublicUrl" :type="item.FileType">
                          </video>
                          <template v-else-if="item.FileType.includes('pdf')">
                            <object class="pt-3 px-3" v-if="!$vuetify.breakpoint.mobile" :data="item.FilePublicUrl"
                                    type="application/pdf"
                                    width="100%" height="100%">
                              <p>PDF görüntülenemiyor. <a :href="item.FilePublicUrl">PDF'i indirmek için tıklayınız.</a>
                              </p>
                            </object>
                            <pdf v-else :src="item.FilePublicUrl"></pdf>
                          </template>
                        </v-row>
                      </v-sheet>
                    </v-carousel-item>
                  </v-carousel>
                  <v-container v-else-if="editedItem.ContentTypeId == 4">
                    <h4 class="text-break font-weight-medium">
                      {{ editedItem.Description }}
                    </h4>
                  </v-container>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex ">
                  <v-icon @click.stop="like(editedItem)" color="red">
                    {{ editedItem.IsLiked ? 'mdi-heart' : 'mdi-heart-outline' }}
                  </v-icon>
                  <v-icon v-if="editedItem.HasComment" @click="openComments(editedItemId)" class="ml-2">
                    mdi-comment-outline
                  </v-icon>
                  <!--                  <v-icon class="ml-3">mdi-bookmark-outline</v-icon>-->
                  <v-spacer></v-spacer>
                  <span>{{ editedItem.CreatedDate | fromNow }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0 d-flex justify-content-between" cols="12">
                  <div class="mt-1">
                    <v-icon class="mr-1" small>mdi-shape</v-icon>
                    {{ editedItem.ContentTypeName }}
                  </div>
                  <div>
                    <v-icon v-if="editedItem.TwitterLink != null && editedItem.TwitterLink.length > 0" class=""
                            color="#1DA1F2" @click="openUrl(editedItem.TwitterLink)">mdi-twitter
                    </v-icon>
                    <v-icon v-if="editedItem.FacebookLink != null && editedItem.FacebookLink.length > 0" class="ml-2"
                            color="#3B5998" @click="openUrl(editedItem.FacebookLink)">mdi-facebook
                    </v-icon>
                    <v-icon v-if="editedItem.InstagramLink != null && editedItem.InstagramLink.length > 0" class="ml-2"
                            color="#d62976" @click="openUrl(editedItem.InstagramLink)">mdi-instagram
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
              <v-row v-show="showComments && editedItem.ShowComments">
                <v-col cols="12" class="py-0">
                  <v-expand-transition>
                    <v-list two-line tile dense v-if="!loadingComments">
                      <v-list-item v-for="comment in editedItem.Comments" :key="comment.Id">
                        <v-avatar size="28" rounded>
                          <v-img v-if="comment.ImageUrl != null" :src="comment.ImageUrl" contain></v-img>
                          <v-icon v-else>mdi-account-circle</v-icon>
                        </v-avatar>
                        <v-list-item-content class="ml-2">
                          <span> <strong class="mr-1">  {{ comment.UserName }}</strong> {{ comment.Text }}</span>
                          <span class="text-muted"> {{ comment.CreatedDate | formattedDateTime }}</span>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon small v-if="comment.IsDeletable" @click="deleteComment(comment)">
                            <v-icon color="red" small>mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-text-field filled rounded class="mx-2 mt-2" clearable
                                    @click:append-outer="addComment(editedItem)" @keydown.enter="addComment(editedItem)"
                                    dense hide-details v-model="editedItem.NewComment" append-outer-icon="mdi-send"
                                    placeholder="yorum ekle..."></v-text-field>
                    </v-list>
                  </v-expand-transition>

                </v-col>
              </v-row>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ApiService from "@/core/services/api.service";
import moment from "moment";
import pdf from 'vue-pdf'
import {Media} from "@capacitor-community/media";
import {Capacitor} from "@capacitor/core";
import {Device} from "@capacitor/device";

export default {
  components: {
    pdf
  },
  data() {
    return {
      contentId: this.$route.params.id,
      loading: false,
      loadingComments: false,
      showComments: false,
      editedItem: {},
    }
  },
  mounted() {
    this.getDataFromApi();
  },
  filters: {
    fromNow: function (date) {
      return moment(date).locale('tr').fromNow();
    },
  },
  watch: {
    $route(to) {
      this.contentId = to.params.id;
      this.getDataFromApi();
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.get("api/Content/" + this.contentId)
          .then(({data}) => {
            this.editedItem = data
            this.getContentFilesFromApi()
          })
          .catch(({response}) => {
            ApiService.showError(response);
            this.loading = false;
          });
    },

    getContentFilesFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post("api/Content/content-files", {ContentId: this.contentId})
          .then(({data}) => {
            this.editedItem.ContentFiles = data

            this.loading = false;
          })
          .catch(({response}) => {
            ApiService.showError(response);
            this.loading = false;
          });
    },

    sendReport() {
      setTimeout(() => {
        ApiService.showAlert("Şikayetiniz alınmıştır. En kısa sürede incelenecektir.", "success")
      }, 500);
    },

    downloadAll(files) {
      if (Capacitor.isNativePlatform()) {
        files.forEach(file => {
          var options = {path: file.FilePublicUrl}
          Device.getInfo().then((device) => {
            if (device.platform == 'android') {
              Media.getAlbums().then((albums) => {
                const album = albums.albums.find(x => x.name == 'Dijital Okulum');
                if (album != null) {
                  options.albumIdentifier = album.identifier;
                  if (file.FileType.includes('image')) {
                    Media.savePhoto(options).then(() => {
                      ApiService.showAlert("Fotoğraf indirildi.", "success")
                    });
                  } else if (file.FileType.includes('video')) {
                    Media.saveVideo(options).then(() => {
                      ApiService.showAlert("Video indirildi.", "success")
                    });
                  }
                } else {
                  Media.createAlbum({name: 'Dijital Okulum'}).then((album) => {
                    options.albumIdentifier = album.identifier;
                    if (file.FileType.includes('image')) {
                      Media.savePhoto(options).then(() => {
                        ApiService.showAlert("Fotoğraf indirildi.", "success")
                      });
                    } else if (file.FileType.includes('video')) {
                      Media.saveVideo(options).then(() => {
                        ApiService.showAlert("Video indirildi.", "success")
                      });
                    }
                  })
                }
              })
            } else { 
              if (file.FileType.includes('image')) {
                Media.savePhoto(options).then(() => {
                  ApiService.showAlert("Fotoğraf indirildi.", "success")
                });
              } else if (file.FileType.includes('video')) {
                Media.saveVideo(options).then(() => {
                  ApiService.showAlert("Video indirildi.", "success")
                });
              }
            }
          })
        })
      } else {
        files.sort((a, b) => {
          if (a.FileType.includes('video') && !b.FileType.includes('video')) return -1;
          if (!a.FileType.includes('video') && b.FileType.includes('video')) return 1;
          return 0;
        });

        var urls = files.map(x => x.FilePublicUrl);

        var interval = setInterval(() => {
          if (urls.length == 0)
            clearInterval(interval);

          var url = urls.pop();
          if (url != null)
            this.forceDownload(url, url.substring(url.lastIndexOf('/') + 1, url.length));
        }, 2500, urls);
      }
    },

    forceDownload(url, fileName) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      }
      xhr.send();
    },

    like(content) {
      ApiService.setHeader()
      ApiService.put("api/Content/content-likes/update", {
        ContentId: content.Id,
        IsLiked: !content.IsLiked
      })
          .then(() => {
            this.editedItem.IsLiked = !content.IsLiked;
          })
          .catch(({response}) => {
            ApiService.showError(response);
          });
    },

    openComments(contentId, toggle = true) {
      if (this.showComments && toggle) {
        this.showComments = false;
        this.editedItem.ShowComments = false
        return;
      }

      this.loadingComments = true;
      ApiService.setHeader()
      ApiService.post("api/Content/content-comments", {
        rowsPerPage: -1,
        page: 1,
        query: {ContentId: contentId}
      })
          .then(({data}) => {
            this.editedItem.Comments = data.Results;
            this.editedItem.ShowComments = true;
            this.showComments = true;
            this.loadingComments = false;
          })
          .catch(({response}) => {
            ApiService.showError(response);
            this.loadingComments = false;
          });
    },


    addComment(content) {
      if (content.NewComment == null || content.NewComment == "") return;
      ApiService.setHeader()
      ApiService.put("api/Content/content-comments", {ContentId: content.Id, Text: content.NewComment})
          .then(() => {
            content.NewComment = null;
            this.openComments(content.Id, false);
          })
          .catch(({response}) => {
            ApiService.showError(response);
          });
    },

    deleteComment(comment) {
      ApiService.setHeader()
      ApiService.delete("api/Content/content-comments/" + comment.Id)
          .then(() => {
            this.openComments(comment.ContentId, false);
          })
          .catch(({response}) => {
            ApiService.showError(response);
          });
    },

    openUrl(url) {
      window.open(url, '_blank');
    }
  },
}
</script>
<style scoped>
video {
  width: 100%;
  height: 95%;
}

.v-image__image {
  background-size: 100% 100%;
}
</style>