var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "d-flex justify-content-center" },
        [
          _c(
            "v-col",
            {
              staticClass: "p-0",
              attrs: { cols: "12", xl: "6", lg: "7", md: "10" },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "mt-5",
                  attrs: { flat: _vm.$vuetify.breakpoint.mobile },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pl-1" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            left: "",
                            color: "primary",
                            to: { name: "pano" },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-arrow-left")]
                          ),
                          _vm._v(" Panoya Git "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0" },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass: "p-4",
                          attrs: { shaped: "", "min-height": "200" },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-2",
                                  attrs: { cols: "auto" },
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { size: "32", rounded: "" } },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-account-circle"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: _vm.$vuetify.breakpoint.mobile
                                      ? "10"
                                      : "auto grow",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pb-0 pt-2 pl-0" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-size-lg font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.editedItem.Title)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "py-0 pr-0 d-flex justify-content-end",
                                        },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { bottom: "", left: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                icon: "",
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-dots-vertical"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  _vm.editedItem
                                                    .ContentTypeId != 4
                                                    ? _c(
                                                        "v-list-item",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                block: "",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.downloadAll(
                                                                      _vm
                                                                        .editedItem
                                                                        .ContentFiles
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    left: "",
                                                                    small: "",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-download"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" İndir "),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-list-item",
                                                    { staticClass: "px-0" },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { text: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.sendReport()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                left: "",
                                                                small: "",
                                                                color: "red",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-exclamation"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " Şikayet Et "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pt-0 pl-0" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.editedItem.CreatedBy
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.editedItem.Description &&
                          _vm.editedItem.ContentTypeId != 4
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-text")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.editedItem.Description) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "p-0" },
                                [
                                  _vm.editedItem.ContentTypeId == 1 &&
                                  _vm.editedItem.ContentFiles.length > 0
                                    ? _c("v-img", {
                                        attrs: {
                                          src: _vm.editedItem.ContentFiles[0]
                                            .FilePublicUrl,
                                          "max-height": _vm.$vuetify.breakpoint
                                            .mobile
                                            ? 320
                                            : 550,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.openUrl(
                                              _vm.editedItem.ContentFiles[0]
                                                .FilePublicUrl
                                            )
                                          },
                                        },
                                      })
                                    : _vm.editedItem.ContentTypeId == 2 &&
                                      _vm.editedItem.ContentFiles.length > 0
                                    ? _c(
                                        "video",
                                        {
                                          attrs: {
                                            controls: "",
                                            name: "media",
                                          },
                                        },
                                        [
                                          _c("source", {
                                            attrs: {
                                              src: _vm.editedItem
                                                .ContentFiles[0].FilePublicUrl,
                                              type: _vm.editedItem
                                                .ContentFiles[0].FileType,
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm.editedItem.ContentTypeId == 3 ||
                                      _vm.editedItem.ContentTypeId == 5
                                    ? _c(
                                        "v-carousel",
                                        {
                                          attrs: {
                                            "progress-color": "green",
                                            "hide-delimiter-background": "",
                                            height: _vm.$vuetify.breakpoint
                                              .mobile
                                              ? 320
                                              : 550,
                                            continuous: false,
                                            "delimiter-icon":
                                              "mdi-circle-slice-8",
                                            "hide-delimiters": "",
                                          },
                                        },
                                        _vm._l(
                                          _vm.editedItem.ContentFiles,
                                          function (item) {
                                            return _c(
                                              "v-carousel-item",
                                              { key: item.Id },
                                              [
                                                _c(
                                                  "v-sheet",
                                                  {
                                                    attrs: {
                                                      height: "100%",
                                                      rounded: "",
                                                      tile: "",
                                                      color: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openUrl(
                                                          item.FilePublicUrl
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "fill-height",
                                                        attrs: {
                                                          align: "top",
                                                          justify: "center",
                                                        },
                                                      },
                                                      [
                                                        item.FileType.includes(
                                                          "image"
                                                        )
                                                          ? _c("v-img", {
                                                              attrs: {
                                                                contain: "",
                                                                id:
                                                                  _vm.editedItemId +
                                                                  "-" +
                                                                  item.Id,
                                                                src: item.FilePublicUrl,
                                                                "max-height":
                                                                  _vm.$vuetify
                                                                    .breakpoint
                                                                    .mobile
                                                                    ? 330
                                                                    : 558,
                                                              },
                                                            })
                                                          : item.FileType.includes(
                                                              "video"
                                                            )
                                                          ? _c(
                                                              "video",
                                                              {
                                                                attrs: {
                                                                  controls: "",
                                                                  name: "media",
                                                                  width: "95%",
                                                                },
                                                              },
                                                              [
                                                                _c("source", {
                                                                  attrs: {
                                                                    src: item.FilePublicUrl,
                                                                    type: item.FileType,
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          : item.FileType.includes(
                                                              "pdf"
                                                            )
                                                          ? [
                                                              !_vm.$vuetify
                                                                .breakpoint
                                                                .mobile
                                                                ? _c(
                                                                    "object",
                                                                    {
                                                                      staticClass:
                                                                        "pt-3 px-3",
                                                                      attrs: {
                                                                        data: item.FilePublicUrl,
                                                                        type: "application/pdf",
                                                                        width:
                                                                          "100%",
                                                                        height:
                                                                          "100%",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          "PDF görüntülenemiyor. "
                                                                        ),
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                href: item.FilePublicUrl,
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "PDF'i indirmek için tıklayınız."
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  )
                                                                : _c("pdf", {
                                                                    attrs: {
                                                                      src: item.FilePublicUrl,
                                                                    },
                                                                  }),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm.editedItem.ContentTypeId == 4
                                    ? _c("v-container", [
                                        _c(
                                          "h4",
                                          {
                                            staticClass:
                                              "text-break font-weight-medium",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.editedItem.Description
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "red" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.like(_vm.editedItem)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.editedItem.IsLiked
                                              ? "mdi-heart"
                                              : "mdi-heart-outline"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm.editedItem.HasComment
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openComments(
                                                _vm.editedItemId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-comment-outline ")]
                                      )
                                    : _vm._e(),
                                  _c("v-spacer"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("fromNow")(
                                          _vm.editedItem.CreatedDate
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "py-0 d-flex justify-content-between",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mt-1" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-shape")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.editedItem.ContentTypeName
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _vm.editedItem.TwitterLink != null &&
                                      _vm.editedItem.TwitterLink.length > 0
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "#1DA1F2" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openUrl(
                                                    _vm.editedItem.TwitterLink
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-twitter ")]
                                          )
                                        : _vm._e(),
                                      _vm.editedItem.FacebookLink != null &&
                                      _vm.editedItem.FacebookLink.length > 0
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { color: "#3B5998" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openUrl(
                                                    _vm.editedItem.FacebookLink
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-facebook ")]
                                          )
                                        : _vm._e(),
                                      _vm.editedItem.InstagramLink != null &&
                                      _vm.editedItem.InstagramLink.length > 0
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { color: "#d62976" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openUrl(
                                                    _vm.editedItem.InstagramLink
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-instagram ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showComments &&
                                    _vm.editedItem.ShowComments,
                                  expression:
                                    "showComments && editedItem.ShowComments",
                                },
                              ],
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-expand-transition",
                                    [
                                      !_vm.loadingComments
                                        ? _c(
                                            "v-list",
                                            {
                                              attrs: {
                                                "two-line": "",
                                                tile: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.editedItem.Comments,
                                                function (comment) {
                                                  return _c(
                                                    "v-list-item",
                                                    { key: comment.Id },
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          attrs: {
                                                            size: "28",
                                                            rounded: "",
                                                          },
                                                        },
                                                        [
                                                          comment.ImageUrl !=
                                                          null
                                                            ? _c("v-img", {
                                                                attrs: {
                                                                  src: comment.ImageUrl,
                                                                  contain: "",
                                                                },
                                                              })
                                                            : _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-account-circle"
                                                                ),
                                                              ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _c("span", [
                                                            _c(
                                                              "strong",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      comment.UserName
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  comment.Text
                                                                )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-muted",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formattedDateTime"
                                                                    )(
                                                                      comment.CreatedDate
                                                                    )
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          comment.IsDeletable
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteComment(
                                                                          comment
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "red",
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              _c("v-text-field", {
                                                staticClass: "mx-2 mt-2",
                                                attrs: {
                                                  filled: "",
                                                  rounded: "",
                                                  clearable: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                  "append-outer-icon":
                                                    "mdi-send",
                                                  placeholder: "yorum ekle...",
                                                },
                                                on: {
                                                  "click:append-outer":
                                                    function ($event) {
                                                      return _vm.addComment(
                                                        _vm.editedItem
                                                      )
                                                    },
                                                  keydown: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.addComment(
                                                      _vm.editedItem
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem.NewComment,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      "NewComment",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem.NewComment",
                                                },
                                              }),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }